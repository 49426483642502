export const CONTRACTS = {
  1: {
    chain_id: 1,
    teleporter: "",
    vault: "",
  },
  137: {
    chain_id: 137,
    teleporter: "",
    vault: "",
  },
  10: {
    chain_id: 10,
    teleporter: "",
    vault: "",
  },
  42161: {
    chain_id: 42161,
    teleporter: "",
    vault: "",
  },
  42220: {
    chain_id: 42220,
    teleporter: "",
    vault: "",
  },
  8453: {
    chain_id: 8453,
    teleporter: "",
    vault: "",
  },
  100: {
    chain_id: 100,
    teleporter: "",
    vault: "",
  },
  43114: {
    chain_id: 43114,
    teleporter: "",
    vault: "",
  },
  250: {
    chain_id: 250,
    teleporter: "",
    vault: "",
  },
  1313161554: {
    chain_id: 1313161554,
    teleporter: "",
    vault: "",
  },
  7777777: {
    chain_id: 7777777,
    teleporter: "",
    vault: "",
  },
  81457: {
    chain_id: 81457,
    teleporter: "",
    vault: "",
  },
  59144: {
    chain_id: 59144,
    teleporter: "",
    vault: "",
  },
  97: {
    //bsc testnet
    chain_id: 97,
    teleporter: "0x568BF299E115D78a1fBa57BafdAe0fD8A26BFb7e",
    vault: "0xE49355609F94A4B8a2EfC6FBd077542F8EC90080",
  },
  8888: {
    // lux testnet
    chain_id: 8888,
    teleporter: "0x23E4BFDAC278FFE22DbBFAc5c1474E10cE7631CD",
    vault: "0x43A4Ab078Edcd3e28385C714950fd099c9595279",
  },
  84532: {
    // base testnet
    chain_id: 84532,
    teleporter: "0x6374d9d1E0CB5eA5e77D92E9a306f7f2ec41660a",
    vault: "0x3Aa3179Bd3A8b1d8269dd840E39f8112fb3eD27a",
  },
  11155111: {
    // sepolia
    chain_id: 11155111,
    teleporter: "0x306533aaedc8d2e28F05a7d42E999730C45aB5B1",
    vault: "0xF2d3BE50D818d48A4e516FbaBb61609F9792CfA6",
  },
};

export const SWAP_PAIRS: Record<string, string[]> = {
  BTC: ["LBTC", "LBTC"],
  WETH: ["LETH", "ZETH"],
  ETH: ["LETH", "ZETH"],
  TON: ["LTON", "ZTON"],
  SOL: ["LSOL", "ZSOL"],
  jUSDC: ["LUSD", "ZUSD"],
  POL: ["LPOL", "ZPOL"],
  BNB: ["LBNB", "ZBNB"],
  FTM: ["LFTM", "ZFTM"],
  DAI: ["LUSD", "ZUSD"],
  USDT: ["LUSD", "ZUSD"],
  USDC: ["LUSD", "ZUSD"],
  CELO: ["LCELO", "ZCELO"],
  XDAI: ["LXDAI", "ZXDAI"],
};
